import React from 'react'
import { withRouter } from "react-router-dom"

class SceneTransition extends React.PureComponent {
    constructor (props) {
        super(props)
        this.state = {
            transitioning: false
        }
        props.history.listen(this.onHistoryChange.bind(this))
    }

    onHistoryChange () {
        this.setState({ transitioning: true })
        setTimeout(() => {
            this.setState({ transitioning: false })
        }, 1500)
    }

    render () {
        return <div className={`SceneTransition ${this.state.transitioning && 'transitioning'}`}>
            <div className="sheet background"></div>
            <div className="sheet foreground"></div>
        </div>
    }
}

export default withRouter(SceneTransition)