import React from 'react'
import MyTicketsWidget from '../MyTicketsWidget'
import TicketItem from '../TicketItem'
import ContextualMenu from './ContextualMenu'

class MyTicketsDialog extends ContextualMenu {
    getTickets () {
        let tickets = this.props.tickets.sort((a, b) => a.object.category.key - b.object.category.key)
        let currentCategory = null
        let render = []
        tickets.forEach(ticket => {
            let { object } = ticket
            if (!currentCategory || (currentCategory.key !== object.category.key)) {
                currentCategory = object.category
                render.push(
                    <div className="category" style={{ color: currentCategory.color }} key={`category-${currentCategory.key}`}>
                        { currentCategory.label }
                    </div>
                )
            }
            render.push(
                <TicketItem
                    accessible={object.category.accessible}
                    label={object.label}
                    key={`ticket-${object.label}`}
                    quantity={object.numSelected}
                />
            )
        })
        return render
    }

    isVisible () {
        return this.props.visible
    }

    getPosition () {
        if (!this.props.settings) {
            return {}
        }
        return this.props.settings.position
    }

    getContents () {
        const hasTickets = this.props.tickets.length > 0
        return <React.Fragment>
            <MyTicketsWidget
                ticketCount={this.props.ticketCount}
                onClick={this.props.onClose}
                remindMePulse={false}
            />
            {
                hasTickets
                    ? <React.Fragment>
                        { this.getTickets() }
                        <div className="button">Get tickets</div>
                    </React.Fragment>
                    : <div className="notice">Click on the floor plan to select your seats</div>
            }
        </React.Fragment>
    }

    render () {
        return <div className={`MyTicketsDialog ${this.isVisible() && 'open'}`} onContextMenu={e => e.preventDefault()}>
            <div className="close-button icon icon-close-thin"></div>
            <div className="dialog" style={this.getPosition()} ref={node => this.wrapperRef = node}>
                { this.getContents() }
            </div>
        </div>
    }
}

export default MyTicketsDialog
