import ContextualMenu from "./ContextualMenu";
import React from "react";

class BestAvailableDialog extends ContextualMenu {

    constructor (props) {
        super(props);
        this.state = {
            numberOfObjects: 1,
            selectedCategories: []
        }
    }

    isVisible () {
        return this.props.visible
    }

    getPosition () {
        if (!this.props.settings) {
            return {}
        }
        return this.props.settings.position
    }

    categoryIsSelected (category) {
        return this.state.selectedCategories.includes(category)
    }

    handleCategorySelectionChange (e, category) {
        if (e.target.checked) {
            this.state.selectedCategories.push(category)
            this.setState({selectedCategories: this.state.selectedCategories})
        } else {
            this.setState({selectedCategories: this.state.selectedCategories.filter(cat => cat !== category)})
        }
    }

    handleNumberOfSeatsChange (e) {
        this.setState({numberOfObjects: e.target.value})
    }

    getContents () {
        return <React.Fragment>
            <label>
                <span className="label">Number of seats</span>
                <input type="number" defaultValue="1" min="1" onChange={e => this.handleNumberOfSeatsChange(e)}></input>
            </label>
            { this.props.categories
                && this.props.categories.map(category => <div key={category}>
                    <label>
                        <input
                            name="category"
                            type="checkbox"
                            checked={this.categoryIsSelected(category)}
                            onChange={e => this.handleCategorySelectionChange(e, category)}
                            value={category}/>
                        <span className="label">{ category }</span>
                    </label>
                </div>)
            }
            <div className="button" onClick={() => {
                if (this.state.numberOfObjects) {
                    return this.props.onOk(parseInt(this.state.numberOfObjects), this.state.selectedCategories);
                }
            }}>Get tickets</div>
        </React.Fragment>
    }

    render () {
        return <div className={`BestAvailableDialog ${this.isVisible() && 'open'}`} onContextMenu={e => e.preventDefault()}>
            <div className="close-button icon icon-close-thin"></div>
            <div className="dialog" style={this.getPosition()} ref={node => this.wrapperRef = node}>
                { this.getContents() }
            </div>
        </div>
    }
}

export default BestAvailableDialog
