import React from 'react'
import { withRouter } from "react-router-dom"
import { SeatsioSeatingChart } from '@seatsio/seatsio-react'
import ContextualMenu from './GUI/ContextualMenu'
import lang from '../util/lang'
import { VENUES } from '../routes/Home'
import MyTicketsWidget from './MyTicketsWidget'
import MyTicketsDialog from './GUI/MyTicketsDialog'
import {eventsService} from "../services/events.service";
import BestAvailableDialog from "./GUI/BestAvailableDialog";
import BestAvailableWidget from "./BestAvailableWidget";

export const FREE_PICKING = 'free-picking'
export const BEST_AVAILABLE = 'best-available'

const STYLE_PRESETS = [
    'balance',
    'bubblegum',
    'flathead',
    'bezels',
    'leaf'
]

const COLOR_SCHEMES = [
    'auto',
    'light',
    'dark'
]

const TOOLTIP_OPTIONS = [
    'showActionHint',
    'showAvailability',
    'showCategory',
    'showLabel',
    'showPricing'
]

let chartCounter = 0

class VenueView extends React.PureComponent {
    constructor(props) {
        super(props)
        const events = Object.keys(props.events)
        this.state = {
            contextualMenu: null,
            showContextualMenu: false,
            seatPicking: FREE_PICKING,
            events: events,
            event: events[0],
            stylePreset: 'balance',
            colorScheme: 'auto',
            objectTooltip: {
                showActionHint: true,
                showAvailability: true,
                showCategory: true,
                showLabel: true,
                showPricing: true
            },
            tickets: [],
            closeTicketsDialog: false,
            ticketsDialogSettings: {},
            visualViewportHeight: null,
            categories: []
        }
        this.chartCounter = chartCounter ++
        this.chart = null
        this.onObjectSelected = this.onObjectSelected.bind(this)
        this.onObjectDeselected = this.onObjectDeselected.bind(this)
        this.onObjectClicked = this.onObjectClicked.bind(this)
        this.onChartRendered = this.onChartRendered.bind(this)
        this.onRenderStarted = this.onRenderStarted.bind(this)
        this.openBestAvailableDialog = this.openBestAvailableDialog.bind(this)
        this.topPanelRef = React.createRef()
    }

    componentDidMount () {
        window.addEventListener('resize', this.onWindowResize.bind(this))
        this.onWindowResize()
    }

    onWindowResize () {
        this.setState({ visualViewportHeight: window.innerHeight })
    }

    getEventData () {
        return this.props.events[this.state.event]
    }

    getContextualOptions () {
        switch(this.state.contextualMenu) {
            case 'selectVenue':
                return mapToOptions(VENUES, this.props.codename)
            case 'selectEvent':
                return mapToOptions(this.state.events, this.state.event)
            case 'selectSeatPicking':
                return mapToOptions(this.getEventData().seatPickingOptions, this.state.seatPicking)
            case 'selectEventAndSeatPicking':
                let eventOptions = this.state.events.length > 1 ? mapToOptions(this.state.events, this.state.event) : null
                let seatPickingOptions = this.getEventData().seatPickingOptions.length > 1 ? mapToOptions(this.getEventData().seatPickingOptions, this.state.seatPicking) : null
                if (eventOptions && seatPickingOptions) {
                    return eventOptions.concat([{ type: 'separator' }]).concat(seatPickingOptions)
                }
                return eventOptions || seatPickingOptions
            case 'stylePresets':
                return mapToOptions(STYLE_PRESETS, this.state.stylePreset)
            case 'colorScheme':
                return mapToOptions(COLOR_SCHEMES, this.state.colorScheme)
            case 'objectTooltip':
                return mapToOptionsDict(TOOLTIP_OPTIONS, this.state.objectTooltip)
            default:
                return []
        }
    }

    openContextualMenu (menu, e) {
        const rect = e.target.getBoundingClientRect()
        this.setState({
            contextualMenu: menu,
            showContextualMenu: true,
            contextualMenuSettings: {
                position: {
                    top: rect.top + rect.height,
                    left: rect.left
                }
            }
        })
    }

    closeContextualMenu () {
        this.setState({ showContextualMenu: false })
    }

    onContextualAction (action) {
        if (VENUES.includes(action)) {
            this.props.history.push(`/${action}`);
        }
        if (COLOR_SCHEMES.includes(action)) {
            this.setState({ colorScheme: action })
        }
        if (STYLE_PRESETS.includes(action)) {
            this.setState({ stylePreset: action })
        }
        if (TOOLTIP_OPTIONS.includes(action)) {
            let objectTooltip = Object.assign({}, this.state.objectTooltip)
            objectTooltip[action] = !objectTooltip[action]
            this.setState({ objectTooltip })
        }
        if (this.state.events.includes(action)) {
            this.setState({ event: action })
        }
        if (this.getEventData().seatPickingOptions && this.getEventData().seatPickingOptions.includes(action)) {
            this.setState({ seatPicking: action })
        }
    }

    getColorScheme () {
        if (this.state.colorScheme === 'auto') {
            return matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'
        }
        return this.state.colorScheme
    }

    usingBestAvailable () {
        return this.state.seatPicking === BEST_AVAILABLE
    }

    getConfiguredSettings () {
        let settings = {}
        if (this.usingBestAvailable()) {
            settings.selectBestAvailable = { number: 2 }
        }
        settings.colorScheme = this.getColorScheme()
        settings.stylePreset = this.state.stylePreset
        settings.objectTooltip = this.state.objectTooltip
        return settings
    }

    getTicketByLabel (tickets, label) {
        return tickets.find(ticket => ticket.object.label === label)
    }

    getTicketIndexByLabel (tickets, label) {
        return tickets.indexOf(this.getTicketByLabel(tickets, label))
    }

    onObjectSelected (object, ticketType) {
        let tickets = this.state.tickets.concat()
        let existingTicketIndex = this.getTicketIndexByLabel(tickets, object.label)
        const entry = { object, ticketType }
        if (existingTicketIndex >= 0) {
            tickets[existingTicketIndex] = entry
        } else {
            tickets.push(entry)
        }
        this.setState({ tickets })
    }

    onObjectClicked () {
        if (this.usingBestAvailable()) {
            this.unpulseSelection()
        }
    }

    onObjectDeselected (object) {
        let tickets = this.state.tickets.filter(ticket => ticket.object.label !== object.label)
        this.setState({ tickets })
    }

    openTicketsDialog (e) {
        const rect = e.target.getBoundingClientRect()
        this.setState({
            showTicketsDialog: true,
            ticketsDialogSettings: {
                position: {
                    top: rect.top,
                    left: rect.left
                }
            }
        })
    }

    closeTicketsDialog () {
        this.setState({ showTicketsDialog: false })
    }

    openBestAvailableDialog (e) {
        const rect = e.target.getBoundingClientRect()
        this.setState({
            showBestAvailableDialog: true,
            bestAvailableDialogSettings: {
                position: {
                    top: rect.bottom,
                    left: rect.left
                }
            }
        })
    }

    closeBestAvailableDialog () {
        this.setState({ showBestAvailableDialog: false })
    }

    getTicketCount () {
        return this.state.tickets.reduce((total, ticket) => total += ticket.object.numSelected || 1, 0)
    }

    onChartRendered (chart) {
        this.chart = chart
        if (this.usingBestAvailable()) {
            this.pulseSelection()
        }
        chart.listCategories().then( categories => this.setState({categories: categories.map(cat => cat.label)}))
    }

    pulseSelection (labels) {
        if (labels) {
            labels.forEach(label => this.chart.findObject(label, o => o.pulse()))
        } else {
            this.state.tickets.forEach(ticket => this.chart.findObject(ticket.object.label, o => o.pulse()))
        }
    }

    unpulseSelection (labels) {
        if (labels) {
            labels.forEach(label => this.chart.findObject(label, o => o.unpulse()))
        } else {
            this.state.tickets.forEach(ticket => this.chart.findObject(ticket.object.label, o => o.unpulse()))
        }
    }

    onRenderStarted () {
        this.setState({ tickets: [] })
    }

    getTargetEmbedHeight () {
        const topPanelRect = this.topPanelRef.current.getBoundingClientRect()
        return this.state.visualViewportHeight - topPanelRect.height
    }

    async doBookBestAvailable(numberOfObjects, selectedCategories, allCategories) {
        eventsService.bookBestAvailable(this.chart.holdToken, this.props.events[this.state.event].seatsioEvent, numberOfObjects, selectedCategories.length > 0 ? selectedCategories : allCategories)
            .then(labels => {
                this.chart.zoomToObjects(labels)
                labels.forEach(label => this.chart.findObject(label).then(object => this.onObjectSelected(object, null)));
                this.pulseSelection(labels);
                window.setTimeout(() => this.unpulseSelection(labels), 3500)
            })
    }

    render () {
        const hasEventOptions = this.state.events.length > 1
        const hasSeatPickingOptions = this.getEventData().seatPickingOptions.length > 1
        const chartStyle = this.state.visualViewportHeight ? { height: `${this.getTargetEmbedHeight()}px` } : {}

        return (
            <div className={`VenueView ${this.state.showTicketsDialog && 'openedTicketsDialog'}`}>
                <div className="frosted-backdrop" style={{ backgroundImage: `url("/static/images/venues/${this.props.codename}-blur.jpg")` }}></div>
                <div className="top-panel" ref={this.topPanelRef}>
                    <div className="background" style={{ backgroundImage: `url("/static/images/venues/${this.props.codename}.jpg")` }}></div>
                    <div className="toolbar desktop-navigation">
                        <div className="content-clip">
                            <div className="frosted-glass" style={{ backgroundImage: `url("/static/images/venues/${this.props.codename}-blur.jpg")` }}></div>
                        </div>
                        <div className="contents breadcrumbs">
                            <div className="item" onClick={() => this.props.history.push('/')}><span className="icon icon-ticket-shop" title="Ticket Shop"></span></div>
                            <div className="item dropdown" onClick={e => this.openContextualMenu('selectVenue', e)}>{ lang.d(this.props.codename) }</div>
                            { hasEventOptions &&
                                <div className="item dropdown" onClick={e => this.openContextualMenu('selectEvent', e)}>{ lang.d(this.state.event) }</div>
                            }
                            { hasSeatPickingOptions &&
                                <div className="item dropdown" onClick={e => this.openContextualMenu('selectSeatPicking', e)}>{ lang.d(this.state.seatPicking) }</div>
                            }
                        </div>
                    </div>
                    <div className="toolbar mobile-navigation">
                        <div className="contents">
                            <div className="item" onClick={() => this.props.history.push('/')}><span className="icon icon-ticket-shop" title="Ticket Shop"></span></div>
                            <div className={`item dropdown ${hasEventOptions && hasSeatPickingOptions && 'combination'}`} onClick={e => this.openContextualMenu('selectEventAndSeatPicking', e)}>
                                <div className="labels">
                                    { hasEventOptions &&
                                        <div className="label">{ lang.d(this.state.event) }</div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="toolbar bottom wide">
                        <div className="content-clip">
                            <div className="frosted-glass" style={{ backgroundImage: `url("/static/images/venues/${this.props.codename}-blur.jpg")` }}></div>
                        </div>
                        <div className="contents menus">
                            <div className={`item ${this.state.showContextualMenu && this.state.contextualMenu === 'colorScheme' && 'selected'}`} onClick={e => this.openContextualMenu('colorScheme', e)}>
                                <div className="icon icon-color-scheme"></div>
                                <div className="label long">Color scheme</div>
                                <div className="label short">Color</div>
                            </div>
                            <div className={`item ${this.state.showContextualMenu && this.state.contextualMenu === 'stylePresets' && 'selected'}`} onClick={e => this.openContextualMenu('stylePresets', e)}>
                                <div className="icon icon-style"></div>
                                <div className="label">Style</div>
                            </div>
                            <div className={`item ${this.state.showContextualMenu && this.state.contextualMenu === 'objectTooltip' && 'selected'}`} onClick={e => this.openContextualMenu('objectTooltip', e)}>
                                <div className="icon icon-tooltip"></div>
                                <div className="label">Tooltip</div>
                            </div>
                        </div>
                        <div className="contents anchor-right">
                            { this.props.events[this.state.event].allowBestAvailable && <div className="contents anchor-right">
                                <BestAvailableWidget
                                    onClick={e => this.openBestAvailableDialog(e)}
                                />
                            </div>}
                            <MyTicketsWidget
                                ticketCount={this.getTicketCount()}
                                onClick={this.openTicketsDialog.bind(this)}
                                remindMePulse={true}
                            />
                        </div>
                    </div>
                </div>
                <div className="chart" style={chartStyle}>
                    <SeatsioSeatingChart
                        id={`chart-${this.props.codename}-${this.chartCounter}`}
                        className={`embed ${this.state.colorScheme !== 'auto' && this.state.colorScheme}`}
                        publicKey="publicDemoKey"
                        event={this.getEventData().seatsioEvent}
                        showFullScreenButton={false}
                        onChartRendered={this.onChartRendered}
                        onObjectSelected={this.onObjectSelected}
                        onObjectDeselected={this.onObjectDeselected}
                        onObjectClicked={this.onObjectClicked}
                        onRenderStarted={this.onRenderStarted}
                        alwaysShowSectionContents={this.getEventData().alwaysShowSectionContents}
                        categoryFilter={this.getEventData().categoryFilter}
                        showMinimap={false}
                        session="continue"
                        pricing={this.getEventData().pricing}
                        priceFormatter={(price) => price + " €"}
                        region="eu"
                        {...this.getConfiguredSettings()}
                    />
                </div>
                <ContextualMenu
                    options={this.getContextualOptions()}
                    visible={this.state.showContextualMenu}
                    onSelect={action => this.onContextualAction(action)}
                    onClose={() => this.closeContextualMenu()}
                    settings={this.state.contextualMenuSettings}
                />
                <MyTicketsDialog
                    visible={this.state.showTicketsDialog}
                    tickets={this.state.tickets}
                    ticketCount={this.getTicketCount()}
                    onClick={this.openTicketsDialog.bind(this)}
                    onClose={() => this.closeTicketsDialog()}
                    settings={this.state.ticketsDialogSettings}
                />
                <BestAvailableDialog
                    visible={this.state.showBestAvailableDialog}
                    categories={this.state.categories}
                    onClick={this.openBestAvailableDialog.bind(this)}
                    settings={this.state.bestAvailableDialogSettings}
                    onClose={() => this.closeBestAvailableDialog()}
                    onOk={(numberOfObjects, selectedCategories) => {
                        this.doBookBestAvailable(numberOfObjects, selectedCategories, this.state.categories)
                            .then(() => this.closeBestAvailableDialog());
                    }}
                />
            </div>
        )
    }
}

function mapToOptions (array, selectedValue = null) {
    return array.map(option => {
        return {
            checked: selectedValue === option,
            caption: lang.d(option),
            uiEvent: option
        }
    })
}

function mapToOptionsDict (array, selectedValues = {}) {
    return array.map(option => {
        return {
            checked: selectedValues[option],
            caption: lang.d(option),
            uiEvent: option
        }
    })
}

export default withRouter(VenueView)
