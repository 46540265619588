import React from 'react'

class BestAvailableWidget extends React.PureComponent {

    render () {
        return <div className="BestAvailableWidget" onClick={(e) => this.props.onClick?.(e)}>
            <div className="icon icon-seats"></div>
            <div className="label">Best Available</div>
        </div>
    }
}

export default BestAvailableWidget
