import React from 'react'
import { Link } from 'react-router-dom'

class Venue extends React.PureComponent {
    render () {
        return (
            <Link to={`/${this.props.codename}`} className="Venue">
                <div className="cover" style={{ backgroundImage: `url("/static/images/venues/${this.props.codename}.jpg")` }}></div>
                <div className="overlay">
                    <div className="frosted-glass" style={{ backgroundImage: `url("/static/images/venues/${this.props.codename}-blur.jpg")` }}></div>
                    <div className="description">
                        <div className="title">{ this.props.title }</div>
                        <div className="subtitle">{ this.props.subtitle }</div>
                    </div>
                </div>
                <div className="preview" style={{ backgroundImage: `url("/static/images/venues/${this.props.codename}-preview@2x.jpg")` }}></div>
            </Link>
        )
    }
}

export default Venue
