import React from 'react'

class TicketItem extends React.PureComponent {
    render () {
        return (
            <div className="TicketItem">
                <div className="price">{ this.props.price }</div>
                <div className="label">{ this.props.label }</div>
                { this.props.quantity > 1 &&
                    <div className="quantity">{ this.props.quantity }</div>
                }
            </div>
        )
    }
}

export default TicketItem