const en = {
    'theatre': 'Theatre',
    'stadium': 'Stadium',
    'comedy-club': 'Comedy club',
    'multifloor': 'Multiple floors',
    'flat': 'Flat',
    'free-picking': 'Free picking',
    'best-available': 'Best available',
    'balance': 'Balance (default)',
    'bubblegum': 'Bubblegum',
    'flathead': 'Flathead',
    'leaf': 'Leaf',
    'bezels': 'Bezels',
    'auto': 'Auto',
    'light': 'Light',
    'dark': 'Dark',
    'showActionHint': 'Show action hint',
    'showAvailability': 'Show availability',
    'showCategory': 'Show category',
    'showLabel': 'Show label',
    'showPricing': 'Show pricing'
}

export default en
