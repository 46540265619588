import axios from 'axios'

export const eventsService = {
    bookBestAvailable
}

function bookBestAvailable(holdToken, event, numberOfObjects, categories) {
    const data = {
        holdToken, event, numberOfObjects, categories
    }
    return axios.post('/api/booking/best-available', data)
        .then(response => response.data.objects)
}
