import React from 'react'
import {
    BrowserRouter as Router,
    Route
} from "react-router-dom"
import { AnimatedSwitch } from 'react-router-transition'
import Preload from 'react-preload'
import Home from "./routes/Home"
import Stadium from './routes/Stadium'
import Theatre from './routes/Theatre'
import SceneTransition from './Components/SceneTransition'
import ComedyClub from './routes/ComedyClub'

require('./DemoTicketShop.less')

function mapStyles(styles) {
    return {
        transform: `translateX(${styles.translateX}%)`,
    }
}

class App extends React.PureComponent {
    constructor (props) {
        super(props)
        this.state = {
            pageTransitioning: false
        }
    }

    componentDidUpdate (prevProps) {
        if (this.props.location !== prevProps.location) {
            this.onRouteChange()
        }
    }

    getFirstStagePreload (theme) {
        return [
            '/static/images/home/a-seatsio-demo-site@2x.png',
            `/static/images/home/gradient-pattern-${theme}@2x.png`,
            `/static/images/home/island-${theme}@2x.png`,
            `/static/images/home/palmtree-${theme}@2x.png`,
            `/static/images/home/ticket-shop-${theme}@2x.png`
        ]
    }

    getSecondStagePreload (theme) {
        return [
            `/static/images/home/gradient-pattern-${theme}@2x.png`,
            `/static/images/home/island-${theme}@2x.png`,
            `/static/images/home/palmtree-${theme}@2x.png`,
            `/static/images/home/ticket-shop-${theme}@2x.png`,
            '/static/images/venues/stadium-blur.jpg',
            '/static/images/venues/stadium-preview@2x.jpg',
            '/static/images/venues/stadium.jpg',
            '/static/images/venues/theatre-blur.jpg',
            '/static/images/venues/theatre-preview@2x.jpg',
            '/static/images/venues/theatre.jpg'
        ]
    }

    getSystemColorScheme () {
        return matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'
    }

    getLoadingIndicator (theme) {
        const isLight = theme === 'light'
        const style = {
            position: 'fixed',
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            backgroundColor: isLight ? '#eee' : '#1a1a1a',
            color: isLight ? '#bebebe' : '#444',
            fontSize: '24px',
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }
        return <div style={style}>
            <div>Loading...</div>
        </div>
    }

    render () {
        const theme = this.getSystemColorScheme()
        const oppositeTheme = theme === 'light' ? 'dark' : 'light'
        return <div className="App">
            <Preload
                loadingIndicator={this.getLoadingIndicator(theme)}
                images={this.getFirstStagePreload(theme)}
                autoResolveDelay={5000}
                resolveOnError={true}
            >
                <Router>
                    <Preload images={this.getSecondStagePreload(oppositeTheme)} mountChildren={false}><span></span></Preload>
                    <SceneTransition />
                    <AnimatedSwitch
                        atEnter={{ translateX: 100 }}
                        atLeave={{ translateX: -100 }}
                        atActive={{ translateX: 0 }}
                        mapStyles={mapStyles}
                        className="switch-wrapper"
                    >
                        <Route exact path='/'>
                            <Home />
                        </Route>
                        <Route exact path='/theatre'>
                            <Theatre />
                        </Route>
                        <Route exact path='/stadium'>
                            <Stadium />
                        </Route>
                        <Route exact path='/comedy-club'>
                            <ComedyClub />
                        </Route>

                        <Route>
                            <div>Not found</div>
                        </Route>
                    </AnimatedSwitch>
                </Router>
            </Preload>
        </div>
    }
}

export default App
