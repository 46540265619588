import React from 'react'
import VenueView, { FREE_PICKING } from '../Components/VenueView'

class Theatre extends React.PureComponent {
    render () {
        let pricing = [
            {'category': 10, 'price': 30}, // 1
            {'category': 11, 'price': 95}, // Diamond
            {'category': 12, 'price': 25}, // Wheelchair & Companion
            {'category': 13, 'price': 70}, // Pearl
            {'category': 14, 'price': 25}, // Restricted View
            {'category': 15, 'price': 65}, // Gold
            {'category': 16, 'price': 520}, // VIP box
            {'category': 17, 'price': 55}, // Silver
            {'category': 18, 'price': 40}, // Bronze
            {'category': 21, 'price': 30}, // No view
            {'category': 22, 'price': 320}, // VIP
        ];
        return (
            <VenueView
                codename="theatre"
                events={{
                    multifloor: {
                        seatsioEvent: 'theatreEvent',
                        seatPickingOptions: [FREE_PICKING],
                        alwaysShowSectionContents: true,
                        pricing: pricing,
                        allowBestAvailable: false
                    },
                    flat: {
                        seatsioEvent: 'theatreEventFlat',
                        seatPickingOptions: [FREE_PICKING],
                        alwaysShowSectionContents: false,
                        pricing: pricing,
                        allowBestAvailable: true
                    }
                }}
            />
        )
    }
}

export default Theatre
