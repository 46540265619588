import React from 'react'
import Button from './Button'

/**
 * @prop {Array} options
 * @prop {Object} settings position { top, right, bottom, left }
 * @prop {Function} onSelect
 * @prop {Function} onClose
 */
export default class ContextualMenu extends React.Component {
    constructor (props) {
        super(props)
        this.handleClickOutside = this.handleClickOutside.bind(this)
        this.handleScroll = this.handleScroll.bind(this)
    }

    close () {
        this.props.onClose()
    }

    componentDidMount () {
        document.addEventListener('mousedown', this.handleClickOutside)
        document.addEventListener('mousewheel', this.handleScroll)
    }

    componentWillUnmount () {
        document.removeEventListener('mousedown', this.handleClickOutside)
        document.removeEventListener('mousewheel', this.handleScroll)
    }

    handleClickOutside (e) {
        if (this.isVisible() && this.wrapperRef && !this.wrapperRef.contains(e.target)) {
            e.preventDefault()
            e.stopPropagation()
            this.onClose()
        }
    }

    handleScroll (e) {
        if (this.isVisible()) {
            e.preventDefault()
        }
    }

    onLink (link) {
        window.open(link)
        this.close()
    }

    onSelect (uiEvent) {
        if (this.props.onSelect) {
            this.props.onSelect(uiEvent)
        }
        this.close()
    }

    onClose () {
        if (this.props.onClose) {
            this.props.onClose()
        }
        this.close()
    }

    getPosition () {
        if (!this.props.settings) {
            return {}
        }
        let position = this.props.settings.position
        if (position && position.left > window.innerWidth * 0.85) {
            position = {
                right: window.innerWidth - position.left,
                top: position.top,
                bottom: position.bottom
            }
        }
        if (position && position.top > window.innerHeight * 0.8) {
            position = {
                right: position.right,
                left: position.left,
                bottom: window.innerHeight - position.top
            }
        }
        return position
    }

    isVisible () {
        return this.props.options && Object.keys(this.props.options).length > 0 && this.props.visible
    }

    getContents () {
        return (this.props.options || []).map((option, i) => {
            switch (option.type) {
                case 'separator':
                    return <div key={`separator-${i}`} className="separator"/>
                case 'title':
                    return <div key={`title-${i}`} className="title">{ option.caption }</div>
                default:
                    let softIcon = null
                    if (option.link) softIcon = 'external-link'
                    if (option.checked) softIcon = 'check'

                    return <Button
                        key={`${option.type}-${option.caption}`}
                        preset="contextual-menu-item"
                        type={option.type}
                        softIcon={softIcon}
                        caption={option.caption}
                        disabled={option.disabled}
                        onMouseUp={() => option.uiEvent ? this.onSelect(option.uiEvent) : this.onLink(option.link)}
                    />
                }
        })
    }

    render () {
        return <div className={`ContextualMenu ${this.isVisible() && 'open'}`} onContextMenu={e => e.preventDefault()} onTouchStart={() => false}>
            <div className="menu" style={this.getPosition()} ref={node => this.wrapperRef = node}>
                { this.getContents() }
            </div>
        </div>
    }
}
