import Polyglot from 'node-polyglot'
import en from '../lang/en'
import { formatNumber } from './helperFunctions'

export const defaultLocale = 'en'

const languages = {
    en
}

export class Language {
    constructor (locale = null) {
        this.locale = locale
        this.polyglot = new Polyglot({
            phrases: languages[this.getLocale()],
            allowMissing: false
        })
    }

    getLocale () {
        return this.locale || defaultLocale
    }

    d (key, params) {
        return this.polyglot.t(key, this.extendWithSmartCount(params))
    }

    dl (key, params) {
        let string = this.d(key, params)
        return string.charAt(0).toLowerCase() + string.slice(1)
    }

    du (key, params) {
        let string = this.d(key, params)
        return string.charAt(0).toUpperCase() + string.slice(1)
    }

    extendWithSmartCount (params = {}) {
        if (params.smart_count !== undefined) {
            return Object.assign({}, { formatted_smart_count: formatNumber(params.smart_count) }, params)
        }
        return Object.assign({}, { smart_count: 0, formatted_smart_count: 0 }, params)
    }
}

const lang = new Language()
export default lang
