import React from 'react'
import VenueView, { FREE_PICKING } from '../Components/VenueView'

class ComedyClub extends React.PureComponent {
    render () {
        let pricing = [
            {'category': 1, 'price': 20}, // Seats
            {'category': 2, 'price': 30}, // Tables
            {'category': 3, 'price': 50}, // Stage Tables
            {'category': 4, 'price': 190}, // VIP Booths
            {'category': 5, 'price': 240}, // VIP Table
        ];
        return (
            <VenueView
                codename="comedy-club"
                events={{
                    comedyClub: {
                        seatsioEvent: 'comedyClubEvent',
                        seatPickingOptions: [FREE_PICKING],
                        categoryFilter: {
                            enabled: true
                        },
                        pricing: pricing,
                        allowBestAvailable: true
                    }
                }}
            />
        )
    }
}

export default ComedyClub
