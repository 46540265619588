import React from 'react'
import VenueView, { FREE_PICKING } from '../Components/VenueView'

class Stadium extends React.PureComponent {
    render () {
        let pricing = [
            {'category': 2, 'price': 90}, // Mezzanine
            {'category': 3, 'price': 80}, // Upper concourse
            {'category': 4, 'price': 180}, // Club Mezzanine
            {'category': 5, 'price': 120}, // Main
            {'category': 6, 'price': 220}, // Club Main
            {'category': 7, 'price': 140}, // Hall of Fame
            {'category': 8, 'price': 240}, // Club Hall of Fame
            {'category': 9, 'price': 65}, // Silver End Zone
            {'category': 10, 'price': 40}, // Club Silver
        ];
        return (
            <VenueView
                codename="stadium"
                events={{
                    stadium: {
                        seatsioEvent: 'largeStadiumEvent',
                        seatPickingOptions: [FREE_PICKING],
                        alwaysShowSectionContents: false,
                        pricing: pricing,
                        allowBestAvailable: true
                    }
                }}
            />
        )
    }
}

export default Stadium
