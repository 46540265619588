import React from 'react'
import Venue from '../Components/Venue'

export const VENUES = [
    'theatre',
    'stadium',
    'comedy-club'
]

function Home() {
    return (
        <div className="Home">
            <div className="hero">
                <div className="vapor-gradient">
                    <div className="pattern"></div>
                </div>
                <div className="palmtree left">
                    <div className="image"></div>
                </div>
                <div className="palmtree right">
                    <div className="flip">
                        <div className="image"></div>
                    </div>
                </div>
                <div className="island"></div>
                <div className="logo">
                    <div className="ticket-shop"></div>
                    <img className="seatsio" src="/static/images/home/a-seatsio-demo-site@2x.png" alt="A seats.io demo site" />
                </div>
            </div>
            <div className="venues">
                <Venue
                    codename="theatre"
                    title="Theatre"
                    subtitle="Kuwait National Cultural District"
                />
                <Venue
                    codename="stadium"
                    title="Stadium"
                    subtitle="AT&T Stadium"
                />
                <Venue
                    codename="comedy-club"
                    title="Comedy club"
                    subtitle="Laugh Factory"
                />
            </div>
        </div>
    )
}

export default Home
