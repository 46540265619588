import React from 'react'

class MyTicketsWidget extends React.PureComponent {
    constructor (props) {
        super(props)
        this.state = {
            pulse: null
        }
    }

    playPulse (type) {
        this.setState({ pulse: type })
        setTimeout(() => this.setState({ pulse: null }), 1000)
    }

    componentDidUpdate (prevProps) {
        if (prevProps.ticketCount < this.props.ticketCount) {
            this.playPulse('inwards')
        }
        if (prevProps.ticketCount > this.props.ticketCount) {
            this.playPulse('outwards')
        }
        if (this.props.remindMePulse && prevProps.ticketCount !== this.props.ticketCount && this.props.ticketCount > 0) {
            clearInterval(this.remindMePulse)
            this.remindMePulse = setInterval(() => this.playPulse('soft'), 5000)
        }
    }

    render () {
        const hasTickets = this.props.ticketCount > 0
        return (
            <div className={`MyTicketsWidget ${this.state.pulse && 'pulse ' + this.state.pulse}`} onClick={this.props.onClick}>
                <div className="icon icon-bar-cocktail"></div>
                <div className="label">My tickets</div>
                <div className={`ticket-count ${hasTickets ? 'visible' : 'hidden'}`}>{ this.props.ticketCount }</div>
            </div>
        )
    }
}

export default MyTicketsWidget
